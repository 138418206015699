// import node module libraries
import { Container, Row, Col } from 'react-bootstrap';
// import custom components
import GKAccordionPlus from 'components/marketing/common/accordions/GKAccordionPlus';
import SectionHeadingCenter from 'components/marketing/common/section-headings/SectionHeadingCenter';

// import data files
import RoadMapData from './RoadMapData';

const FAQsection = () => {
	const title = 'The Road Map';
	const subtitle = '';
	const description = `Zeron Protocol is getting better and stronger, please keep join and following us.
`;

	return (
		<div className="py-8 py-lg-18 bg-white">
			<Container>
				<SectionHeadingCenter
					title={title}
					description={description}
					subtitle={subtitle}
				/>
				<Row className="justify-content-center">
					<Col lg={6} md={8} sm={12}>
						<GKAccordionPlus accordionItems={RoadMapData} itemClass="px-0" />
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default FAQsection;
