// import node module libraries
import { Fragment } from 'react';

// import custom components
import Features4Columns from './Features4Columns';
import HeroTyped from './HeroTyped';
// import required data files
import CTADarkBG from '../../common/call-to-action/CTADarkBG';
import RoadMapNew from './RoadMapNew';

const title = 'What is $ZNT';
const subtitle = 'The $ZNT';
const description = `$ZNT is an ERC20-compatible token with a fixed supply that governs Zeron Protocol, built using the industry-leading OpenZeppelin standard. $ZNT holders can vote to resolve the dispute via governance.`;
const btntext = 'FIND OUT MORE ABOUT $ZNT';
const btnlink = 'https://docs.zeron.work/ecosystem/usdznt';

const HomePage = () => {
	return (
		<Fragment>
			<HeroTyped />
			<Features4Columns />
			<CTADarkBG
				title={title}
				description={description}
				subtitle={subtitle}
				btntext={btntext}
				btnlink={btnlink}
			/>
			<RoadMapNew />
		</Fragment>
	);
};
export default HomePage;
