// Section : Features
// Style : Four Columns Features Section

// import node module libraries
import { Col, Row, Container } from 'react-bootstrap';

// import custom components
import SectionHeadingCenter from 'components/marketing/common/section-headings/SectionHeadingCenter';
import FeatureTopIcon from 'components/marketing/common/features/FeatureTopIcon';

const Features4Columns = () => {
	const title = 'Why Zeron';
	// const subtitle = 'ssuring payment for the work done';
	const description = ` Solved the challenge of hiring unknown developers as "Freelancers" for a client's project, whilst assuring payment for the work done.`;

	const features = [
		{
			id: 1,
			icon: 'dollar-sign',
			title: 'Crypto Payments',
			description: `You can pay for all services with native cryptocurrency.`
		},
		{
			id: 2,
			icon: 'thumbs-up',
			title: 'Dispute Resolution',
			description: `A decentralized arbitration platform that can handle subjective disputes.`
		},
		{
			id: 3,
			icon: 'pocket',
			title: 'Zero Fees',
			description: `The transaction is free. 0.1% of the transaction fee will be charged for witnesses' rewards only  if the dispute is involved.`
		},
		{
			id: 4,
			icon: 'shield',
			title: 'Trustless',
			description: `You don't have to rely on third-party agents. Transactions can be done trustlessly and safely.`
		}
	];

	return (
		<div className="bg-white">
			<Container>
				<SectionHeadingCenter
					title={title}
					description={description}
					subtitle={""}
				/>
				<Row>
					{features.map((item, index) => {
						return (
							<Col lg={3} md={6} sm={12} key={index}>
								<FeatureTopIcon item={item} />
							</Col>
						);
					})}
				</Row>
			</Container>
		</div>
	);
};

export default Features4Columns;
