import { v4 as uuid } from 'uuid';

const NavbarDefault = [
	{
		id: uuid(),
		menuitem: 'Home',
		link: '/'
	},
	{
		id: uuid(),
		menuitem: 'APP (coming soon)',
		link: '#'
	},
	// {
	// 	id: uuid(),
	// 	menuitem: 'Stake (coming soon)',
	// 	link: '#'
	// },
	// {
	// 	id: uuid(),
	// 	menuitem: 'Governance (coming soon)',
	// 	link: '#'
	// },
	// {
	// 	id: uuid(),
	// 	menuitem: 'Forum (coming soon)',
	// 	link: '#'
	// },
	{
		id: uuid(),
		menuitem: 'Docs',
		link: 'https://docs.zeron.work'
	}
];

export default NavbarDefault;
