import { v4 as uuid } from 'uuid';

export const FAQsData = [
	{
		id: uuid(),
		title: '2023.Q1',
		content: (
			<div className="mt-4">
				{/* list */}
				<ul className="list-unstyled">
					<li className="d-flex mb-4 ">
						<i className="fe fe-check-circle me-2 text-primary mt-1"></i>
						Set up a founding team
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-check-circle me-2 text-primary mt-1"></i>
						Website Development
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-check-circle me-2 text-primary mt-1"></i>
						Whitepaper Documentation
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Start ICO Plan
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Set up Community - Twitter/Telegram/Discord
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Deployment Phase - Token & Arbitral Contract - Testnet
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Contract Audit
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Deployment Phase - Token & Arbitral Contract - Mainnet
					</li>
				</ul>
			</div>
		)
	},
	{
		id: uuid(),
		title: '2023.Q2',
		content: (
			<div className="mt-4">
				{/* list */}
				<ul className="list-unstyled">
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						5000+ Twitter followers
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						3000+ Telegram members
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						3000+ Discord members
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						DEX Launch & Liquidity Locked
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Deployment Phase - Route & Employment Contract - Testnet
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Contract Audit
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Deployment Phase - Route & Employment Contract - Mainnet
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Beta Release
					</li>
				</ul>
			</div>
		)
	},
	{
		id: uuid(),
		title: '2023.Q3',
		content: (
			<div className="mt-4">
				{/* list */}
				<ul className="list-unstyled">
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Official launch
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						CoinMarketCap Listing
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						CoinGecko Listing
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Further expansion of the marketing squad
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						KOL Marketing
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Holder 5000+
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Launch the Zeron Forum hosted on Arweave
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Enable Snapshot or Tally for decentralized autonomous organizations
					</li>
				</ul>
			</div>
		)
	},
	{
		id: uuid(),
		title: '2023.Q4',
		content: (
			<div className="mt-4">
				{/* list */}
				<ul className="list-unstyled">
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Further expansion of the dev squad
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						Refine Contract based on community feedback
					</li>
					<li className="d-flex mb-4 ">
						<i className="fe fe-x-circle me-2 text-primary mt-1"></i>
						...
					</li>
				</ul>
			</div>
		)
	}
];

export default FAQsData;
