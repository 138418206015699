// import node module libraries
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image, Navbar, Nav, Container } from 'react-bootstrap';

// simple bar scrolling used for notification item scrolling
import 'simplebar/dist/simplebar.min.css';

// import custom components

// import media files
import Logo from 'assets/images/brand/logo/logoNew.jpeg';

// import data files
import NavbarDefaultRoutes from 'routes/marketing/NavbarDefault';
import NavDropdownMain from 'layouts/marketing/navbars/NavDropdownMain';

const NavbarDefault = ({ headerstyle, login }) => {
	const [expandedMenu, setExpandedMenu] = useState(false);
	return (
		<Fragment>
			<Navbar
				onToggle={(collapsed) => setExpandedMenu(collapsed)}
				expanded={expandedMenu}
				expand="lg"
				className={`${login ? 'bg-white' : ''} navbar p-2 ${
					headerstyle === 'dark' ? 'navbar-dark bg-dark' : 'navbar-default py-2'
				}`}
			>
				<Container fluid className="px-0 ps-2">
					<Navbar.Brand as={Link} to="/">
						<Image src={Logo} alt="" />
					</Navbar.Brand>
					<span className={'brand-name'}>Zeron</span>
					<Navbar.Toggle aria-controls="basic-navbar-nav">
						<span className="icon-bar top-bar mt-0"></span>
						<span className="icon-bar middle-bar"></span>
						<span className="icon-bar bottom-bar"></span>
					</Navbar.Toggle>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav>
							{NavbarDefaultRoutes.map((item, index) => {
								if (item.children === undefined) {
									if (item.link && item.link.includes('https'))
										return (
											<Nav.Link key={index} href={item.link}>
												{item.menuitem}
											</Nav.Link>
										);
									else
										return (
											<Nav.Link key={index} as={Link} to={item.link}>
												{item.menuitem}
											</Nav.Link>
										);
								} else {
									return (
										<NavDropdownMain
											item={item}
											key={index}
											onClick={(value) => setExpandedMenu(value)}
										/>
									);
								}
							})}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</Fragment>
	);
};

// Specifies the default values for props
NavbarDefault.defaultProps = {
	headerstyle: 'navbar-default',
	login: false
};

// Typechecking With PropTypes
NavbarDefault.propTypes = {
	headerstyle: PropTypes.string,
	login: PropTypes.bool
};

export default NavbarDefault;
