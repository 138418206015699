// ** Import from react dom
import { Route, Switch, Redirect } from 'react-router-dom';
// ** Import core SCSS styles
import 'assets/scss/theme.scss';
/* ----------------------------------- */
/* IMPORTS FOR MARKETING PAGES - START */
import DefaultLayout from 'layouts/marketing/DefaultLayout';
import NotFound from 'layouts/marketing/NotFound';

import HomePage from 'components/marketing/pages/home/HomePage';
/* IMPORTS FOR FRONT SPECIALTY SUBMENU  ROUTERS */
import Error404 from 'components/marketing/pages/specialty/Error404';
// Landing menu item pages

/* IMPORTS FOR MARKETING PAGES - END */
/* --------------------------------- */

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (
			<Layout>
				<Component {...props}></Component>
			</Layout>
		)}
	></Route>
);

function AllRoutes() {
	return (
		<Switch>
			{/* --------------------------- */}
			{/* LANDING PAGES ROUTERS - START */}
			<AppRoute exact path="/" layout={DefaultLayout} component={HomePage} />
			<AppRoute
				exact
				path="/marketing/specialty/404-error/"
				layout={NotFound}
				component={Error404}
			/>
			{/* ------------------------- */}
			{/*Redirect*/}
			<Redirect to="/marketing/specialty/404-error/" />
		</Switch>
	);
}

export default AllRoutes;
