// import node module libraries
import { Link } from 'react-router-dom';
import { Col, Row, Image } from 'react-bootstrap';

// import media files
import Logo from 'assets/images/brand/logo/logoNew.jpeg';
const styles = { width: '2.5rem', marginLeft: '1rem' };
const testStyle = {
	color: '#754ffe',
	fontWeight: 800,
	fontSize: '1.2rem',
	marginRight: '1rem',
	marginLeft: '1rem'
};

const NavbarBrandOnly = () => {
	return (
		<Row>
			<Col xl={{ offset: 1, span: 2 }} lg={12} md={12}>
				<div className="mt-4">
					<Link to="/">
						<Image style={styles} src={Logo} alt="" />
						<span style={testStyle}>Zeron</span>
					</Link>
				</div>
			</Col>
		</Row>
	);
};
export default NavbarBrandOnly;
